var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['sygni-color-picker']
  }, [_c('div', {
    staticClass: "sygni-color-picker__btn",
    on: {
      "click": _vm.toggleShowColors
    }
  }, [_c('div', {
    staticClass: "sygni-color-picker__placeholder",
    style: _vm.selectedColorStyles
  })]), _c('simplebar', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showColors,
      expression: "showColors"
    }],
    staticClass: "sygni-color-picker__options",
    attrs: {
      "data-simplebar-auto-hide": "false"
    }
  }, [_c('div', {
    staticClass: "sygni-color-picker__options-inner"
  }, _vm._l(_vm.colors, function (color) {
    return _c('div', {
      key: color,
      staticClass: "sygni-color",
      style: _vm.colorStyles(color),
      on: {
        "click": function click($event) {
          return _vm.selectColor(color);
        }
      }
    });
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }