var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "invite-investors-modal"
  }, [_c('div', {
    staticClass: "invite-investors-modal__bg",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "invite-investors-modal__box"
  }, [_c('div', {
    class: ['invite-investors-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "invite-investors-modal__actions"
  }, [_c('h2', {
    staticClass: "invite-investors-modal__header"
  }, [_vm._v("Invite investor")]), _c('button', {
    staticClass: "invite-investors-modal__action invite-investors-modal__action--close",
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v("Close")])]), _vm._m(0), _c('div', {
    staticClass: "invite-investors-modal__footer"
  }, [_c('div', {
    staticClass: "invite-investors-modal__footer-inner"
  }, [_c('div', {
    staticClass: "invite-investors-modal__footer-input"
  }, [_c('sygni-input', {
    attrs: {
      "type": "email",
      "label": "Enter investor email"
    }
  }), _c('sygni-rect-button', {
    staticClass: "filled gn-secondary invite-investors-modal__btn",
    on: {
      "click": function click($event) {
        return _vm.$emit('inviteSent');
      }
    }
  }, [_vm._v("Send invite")])], 1)]), _c('p', {
    staticClass: "invite-investors-modal__header"
  }, [_vm._v("Invited investors")]), _c('div', {
    staticClass: "invite-investors-modal__footer-inner"
  }, [_c('div', {
    staticClass: "invite-investors-modal__people"
  }, [_c('div', {
    staticClass: "invite-investors-modal__person"
  }, [_c('user-presentation', {
    attrs: {
      "name": "Elżbieta Doradcza",
      "description": "edoradcza@test.pl",
      "image": null
    }
  }), _c('div', {
    staticClass: "invite-investors-modal__person-status rejected"
  }, [_vm._v("REJECTED")])], 1), _c('div', {
    staticClass: "invite-investors-modal__person"
  }, [_c('user-presentation', {
    attrs: {
      "name": "Jan Nowak",
      "description": "jnowak@gmail.com",
      "image": require('@/assets/images/avatar-3.jpg')
    }
  }), _c('div', {
    staticClass: "invite-investors-modal__person-status awaiting"
  }, [_vm._v("AWAITING")])], 1), _c('div', {
    staticClass: "invite-investors-modal__person"
  }, [_c('user-presentation', {
    attrs: {
      "name": "Małgorzata Stemplowska",
      "description": "goskastempel@mstepel.pl",
      "image": require('@/assets/images/avatar-1.jpg')
    }
  }), _c('div', {
    staticClass: "invite-investors-modal__person-status active"
  }, [_vm._v("ACTIVE")])], 1), _c('div', {
    staticClass: "invite-investors-modal__person"
  }, [_c('user-presentation', {
    attrs: {
      "name": "Anna Kowalska",
      "description": "akowal@kowalska.pl",
      "image": require('@/assets/images/avatar-2.jpg')
    }
  }), _c('div', {
    staticClass: "invite-investors-modal__person-status awaiting"
  }, [_vm._v("AWAITING")])], 1)])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "invite-investors-modal__inner"
  }, [_c('div', {
    staticClass: "invite-investors-modal__body"
  }, [_c('p', {
    staticClass: "invite-investors-modal__label"
  }, [_vm._v("CAMPAIGN NAME")]), _c('p', {
    staticClass: "invite-investors-modal__header"
  }, [_vm._v("It's time to invest in new Start-up!")])])]);
}]

export { render, staticRenderFns }