














import Vue from 'vue';
import Component from 'vue-class-component'
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import InviteInvestorsModal from '../../../components/InviteInvestorsModal.vue';
import LandingPage from '../../../components/LandingPage.vue';

@Component({
  components: { SygniRectButton, LandingPage, InviteInvestorsModal },
})
export default class AddNewCampaignModule extends Vue {
  showInvestorsModal: boolean = false;

  openInvestorsModal() {
    this.showInvestorsModal = true;
  }

  closeInvestorsModal() {
    this.showInvestorsModal = false;
  }
}
