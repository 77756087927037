import { render, staticRenderFns } from "./InviteInvestorsModal.vue?vue&type=template&id=31e7ba42&scoped=true&"
import script from "./InviteInvestorsModal.vue?vue&type=script&lang=ts&"
export * from "./InviteInvestorsModal.vue?vue&type=script&lang=ts&"
import style0 from "./InviteInvestorsModal.vue?vue&type=style&index=0&id=31e7ba42&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31e7ba42",
  null
  
)

export default component.exports