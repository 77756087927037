var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "campaign-preview"
  }, [_c('div', {
    staticClass: "campaign-preview__main"
  }, [_c('div', {
    staticClass: "campaign-preview__container"
  }, [_c('landing-page', {
    on: {
      "invite": _vm.openInvestorsModal
    }
  })], 1)]), _c('invite-investors-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showInvestorsModal,
      expression: "showInvestorsModal"
    }],
    on: {
      "close": _vm.closeInvestorsModal,
      "inviteSent": _vm.closeInvestorsModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }