var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sygni-flag",
    style: "color: ".concat(_vm.color)
  }, [_vm.color ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "12.762",
      "height": "18.307",
      "viewBox": "0 0 12.762 18.307"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_1810",
      "data-name": "Group 1810",
      "transform": "translate(0 0)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_1280",
      "data-name": "Path 1280",
      "d": "M11.106,0H1.655A1.657,1.657,0,0,0,0,1.655V17.708a.6.6,0,0,0,.294.517.6.6,0,0,0,.595.006L6.228,15.27a.316.316,0,0,1,.3,0l5.34,2.962a.591.591,0,0,0,.291.076.6.6,0,0,0,.6-.6V1.655A1.657,1.657,0,0,0,11.106,0",
      "fill": "currentColor"
    }
  })])]) : _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "12.762",
      "height": "18.307",
      "viewBox": "0 0 12.762 18.307"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_1798",
      "data-name": "Group 1798",
      "transform": "translate(0 0)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_1797",
      "data-name": "Group 1797"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_1268",
      "data-name": "Path 1268",
      "d": "M12.163,18.307a.593.593,0,0,1-.291-.075l-5.34-2.963a.319.319,0,0,0-.3,0L.89,18.232A.6.6,0,0,1,0,17.708V1.656A1.657,1.657,0,0,1,1.654,0h9.452a1.658,1.658,0,0,1,1.656,1.656V3.144a.6.6,0,1,1-1.2,0V1.656a.458.458,0,0,0-.458-.457H1.654a.458.458,0,0,0-.457.457V16.691l4.45-2.469a1.5,1.5,0,0,1,1.466,0l4.451,2.47V6.322a.6.6,0,0,1,1.2,0V17.708a.6.6,0,0,1-.6.6",
      "fill": "#38303b"
    }
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }