













import Vue from 'vue'
import Component, { mixins } from 'vue-class-component'
import { Prop } from "vue-property-decorator";
import SygniInput from "@/components/inputs/SygniInput.vue";
import { SygniValidation } from '@/shared/mixins/ValidationMixin';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';

@Component({
  components: {SygniInput, simplebar}
})
export default class SygniColorPicker extends mixins(Vue, SygniValidation) {
  @Prop() label!: String;

  showColors: boolean = false;
  selectedColor: string = '';
  colors: Array<string> = [
    '#C80100',
    '#F5AB45',
    '#F5E145',
    '#45BC82',
    '#20D5CE',
    '#3281D3',
    '#7452D1',
    '#D152D1',
    '#D3D3D3',
    '#38303B'
  ]

  clearColor() {
    this.selectedColor = '';
    this.showColors = false;
    this.$emit('change', this.selectedColor);
  }

  toggleShowColors() {
    this.showColors = !this.showColors;
  }

  selectColor(color: string) {
    this.selectedColor = (this.selectedColor != color) ? color : '';
    this.showColors = false;
    this.$emit('change', this.selectedColor);
  }

  get selectedColorStyles() {
    return this.selectedColor ? `background: ${this.selectedColor}` : ''
  }

  colorStyles(color: string) {
    return (this.selectedColor == color) ? `background-color: ${color}; border: 2px solid var(--black);` : `background-color: ${color}`;
  }
}
